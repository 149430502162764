<template>
    <main class="FilteredSearch-MAIN">
        <div v-if="!viewInfo" class="INNER-MARGIN" data-aos="flip-left">

            <p><b>Selecciona tu preferencia</b> y haz que tu estadía en Acapulco Diamante sea inolvidable.</p>
            <h2>¡Reserva ahora y vive la experiencia única de "<b>Sand Diamante</b>"!</h2>

            <!--repeat-->
            <div class="filtered-card" v-for="(product, index) in filteredProducts" :key="index">
                <!--<img :src="product.img">-->
                <ImgSlider :imgList="product.img" class="slider-img-icons"/>
                <div>
                    <h3>{{ product.name }}</h3>
                    <p class="card-meters"><img src="../assets/images/icon-location.svg"/><b>&nbsp; {{ product.items.meters }}m<sup>2</sup></b></p>
                    <div class="card-items">
                        <div>
                            <p><img src="../assets/images/icon-bed.svg"/>&nbsp; {{ product.items.rooms }} habitaciones</p>
                            <p><img src="../assets/images/icon-table.svg"/>&nbsp; {{ product.items.terrazas }} terrazas</p>
                            <p><img src="../assets/images/piscina.svg"/>&nbsp; Alberca</p>
                            <!--<p><img src="../assets/images/silla-de-oficina.svg"/>&nbsp; Área de coworking</p>-->
                        </div>
                        <div>
                            <p><img src="../assets/images/icon-bath.svg"/>&nbsp; {{ product.items.bathrooms }} baños</p>
                            <p><img src="../assets/images/icon-car.svg"/>&nbsp; {{ product.items.estacionamiento }} estacionamiento</p>
                            <p><img src="../assets/images/pesa.svg"/>&nbsp; Gimnasio</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="card-price-content">
                        <p :style="`${product.subPrice ? 'margin-top: 3.5ch;' : ''}`">${{ product.price }} <span>{{ product.priceMode }}</span></p>
                        <p v-if="product.subPrice" v-html="product.subPrice" class="sub-price"></p>
                        <div class="discount-sign" v-if="product.discount">
                            <span>{{ product.discount }}<sup>%</sup></span><span>de descuento</span>
                        </div>
                    </div>
                    <button class="btn-mas-informacion" @click="showInfo(product)">MÁS INFORMACIÓN</button>
                    <button class="btn-reservar" @click="checkAvailability(product), saveProduct(product)">
                        {{
                            product.supportedTime.includes('Fin de semana') || product.supportedTime.includes('Semana')
                            ? 'RESERVAR' : 'AGENDAR LLAMADA'
                        }}
                    </button>
                </div>
            </div>

        </div>

        <ViewInfo v-else :productData="selectedProduct"/>
    </main>
</template>

<script>
import ImgSlider from './ImgSlider.vue';
import ViewInfo from './ViewInfo.vue';
export default {
  components: { ViewInfo, ImgSlider },
    name: 'FilteredSearch',
    props:{
        filteredProducts: Array
    },
    data(){
        return{
            selectedProduct: {},
            viewInfo: false,
        }
    },
    methods:{
        showInfo(product_){
            this.selectedProduct = product_;
            this.viewInfo = true;
        },
        
        closeInfo(){
            this.viewInfo = false;
        },

        launchPopUp(mode_){
            this.$parent.launchPopUp(mode_);
        },

        checkAvailability(data_){

            const searchData = JSON.parse(localStorage.getItem("searchData"));
            fetch('https://admin.sand-diamante.mx/api/vigencia', {
            method: 'POST',
                body: JSON.stringify({
                    "fecha_inicial": searchData.arrival,
                    "fecha_final": searchData.exit,
                    "id": data_.id
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                if(json.msg == "Disponible"){
                    this.launchPopUp(data_.supportedTime);
                }else{
                    this.launchPopUp("notAvailable");
                }
            });
    
        },

        saveProduct(product_){
            console.log("Save product data");
            localStorage.setItem("productData", JSON.stringify(product_));
        }
    },
}
</script>

<style scoped>
.FilteredSearch-MAIN{
    background-color: #F0F0F0;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FilteredSearch-MAIN .INNER-MARGIN{
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
    padding-bottom: 4ch;
}

.FilteredSearch-MAIN .INNER-MARGIN > p{
    font-size: 1.7ch;
    margin-top: 2.5ch;
    margin-bottom: 0;
}

.FilteredSearch-MAIN .INNER-MARGIN > h2{
    color: #1D7ABE;
    margin-top: 0.5ch;
    font-weight: 500;
}

/* cards */
.filtered-card{
    background-color: white;
    width: calc(100% - 4ch);
    padding: 2ch;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 1ch 0;
    position: relative;
    height: 220px;
    overflow: hidden;
}

.filtered-card > img, .filtered-card > .slider-img-icons{
    width: 220px;
    aspect-ratio: 1/1 !important;
    object-fit: cover;
    align-self: center;
}

.filtered-card > div:nth-child(2){
    width: 44%;
}

.filtered-card > div:nth-child(3){
    width: 23%;
}

.filtered-card h3{
    color: #167AC0;
    font-size: 2ch;
    margin: 0;
}

.filtered-card > div > p{
    margin-bottom: 2.5ch;
}

.filtered-card p {
    display: flex;
    align-items: center;
}

.filtered-card p img{
    width: 1.8ch;
    max-height: 1.8ch;
    margin: 0;
}

.filtered-card > div:nth-child(2) > div{
    display: flex;
    margin-top: 1ch;
}

.filtered-card > div:nth-child(2) > div p{
    margin: 0;
    margin-bottom: 1ch;
}

.filtered-card > div:nth-child(2) > div > *{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.filtered-card > div:nth-child(3){
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.filtered-card > div:nth-child(3) button{
    height: 6ch;
    border: none;
    padding: 0 2ch;
    font-weight: 600;
    color: white;
    width: 80%;
    margin-top: 2ch;
}

.btn-mas-informacion{
    background-color: #5FB6E4;
}

.btn-reservar{
    background-color: #167AC0;
}

.card-price-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #152F62;
}

.card-price-content p{
    font-size: 2.2ch;
    display: unset;
    margin: 0;
}

.card-price-content p span{
    font-size: 1ch !important;
    margin-left: 0.2ch;
}

.sub-price{
    font-size: 1.25ch !important;
    font-weight: 500;
}

.discount-sign{
    background-color: #89FFD4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1ch;
    line-height: 1.8ch;
    border-radius: 0 0 100vw 100vw;
    width: 6ch;
    white-space: nowrap;
    padding-bottom: 1.5ch;
    position: absolute;
    top: -2ch;
    box-shadow: 0px 0px 1ch rgba(0, 0, 0, 0.25);
}

.discount-sign > span:nth-child(1){
    font-size: 3.5ch;
    font-weight: bold;
    margin-bottom: 0;
}

.discount-sign > span:nth-child(1) sup{
    font-size: 0.8ch;
}

.discount-sign > span:nth-child(2){
    font-size: 0.8ch;
    font-weight: 600;
    margin-top: 0;
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .INNER-MARGIN > p:nth-child(1){
        text-align: center;
        margin-top: 5vw;
        margin-bottom: 2vw;
        font-size: 4.4vw;
    }

    .INNER-MARGIN > h2{
        text-align: center;
        font-size: 7vw;
        width: 85%;
    }

    /* cards */
    .filtered-card{
        flex-direction: column;
        align-items: center;
        justify-content: unset;
        height: fit-content;
        padding: 0;
        width: 98%;
        box-shadow: 0 0 1ch rgba(100, 100, 100, 0.2);
        margin-bottom: 5vw;
    }

    .filtered-card > div{
        width: 100% !important;
        max-width: unset;
    }

    .filtered-card > img{
        width: 100%;
        aspect-ratio: 1.5/1 !important;
        margin-bottom: 1.5ch;
    }

    .filtered-card > div:nth-child(2){
        width: 85% !important;
        display: flex;
        flex-direction: column;
    }

    .filtered-card h3{
        font-size: 6vw;
    }

    .filtered-card > div:nth-child(2) p{
        font-size: 4.5vw;
    }

    .card-meters{
        position: absolute;
        top: 1.5ch;
        right: 0;
        background-color: white;
        padding: 0.4ch 0.8ch;
        padding-right: 1ch;
        box-shadow: 0 0 1ch rgba(50, 50, 50, 0.5);
    }

    .card-items{
        display: flex;
        flex-direction: column;
    }

    .card-items > div{
        width: 100% !important;
    }

    .filtered-card > div:nth-child(3){
        width: 65% !important;
        text-align: center;
        margin-top: 2ch;
        margin-bottom: 4ch;
        padding: 0 2ch;
        padding-top: 2ch;
        border-top: 1px solid #1d7bbe7d;
    }

    .filtered-card > div:nth-child(3) p, .filtered-card > div:nth-child(3) span{
        display: flex;
        white-space: nowrap;
    }

    .filtered-card > div:nth-child(3) p{
        align-items: flex-end;
        margin-bottom: 0.5ch;
    }

    .filtered-card > div:nth-child(3) span{
        margin-bottom: 0.2ch;
        margin-left: 1ch;
    }

    .filtered-card > div:nth-child(3) button{
        width: 100%;
    }

}
</style>