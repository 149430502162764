<template>
    <div class="PopUpRestricciones-MAIN">
        <h1 class="PopUp-title"><img src="../../assets/images/forbidden.svg">RESTRICCIONES</h1>

        <p class="restricciones-title restricciones-asistentes"><img src="../../assets/images/usuarios.svg">Límite de huéspedes: 6 <!--Máximo: 8 personas--></p>

        <div class="restricciones-grid">
            <div>
                <p class="restricciones-title"><img src="../../assets/images/reloj.svg"/> Hora de llegada:</p>
                <p class="restricciones-subtitle">Después de las 13:00 hrs</p>
            </div>
            <div>
                <p class="restricciones-title"><img src="../../assets/images/reloj.svg"/> Hora de salida:</p>
                <p class="restricciones-subtitle">Antes de las 12:00 hrs</p>
            </div>
            <div>
                <p class="restricciones-title"><img src="../../assets/images/volume-high-slash.svg"/> Horas de silencio:</p>
                <p class="restricciones-subtitle">23:00 - 07:00 hrs</p>
            </div>
            <div>
                <p class="restricciones-subtitle"><img src="../../assets/images/no-fiesta.svg">No se permiten fiestas ni eventos</p>
            </div>
            <div>
                <p class="restricciones-subtitle"><img src="../../assets/images/no-foto.svg">Fotografía comercial no autorizada</p>
            </div>
            <div>
                <p class="restricciones-subtitle"><img src="../../assets/images/no-fumar.svg">100% Libre de humo</p>
            </div>
        </div>

        <!-- {{ this.getData() }} -->
        <div class="checkboxes-info">
            <div><label><input type="checkbox" v-model="acceptedPoliticas"></label><p @click="downloadPdf('politicas_aviso_privacidad.pdf')">Politicas y aviso de privacidad</p></div>
            <div><label><input type="checkbox" v-model="acceptedReglamento"></label><p @click="downloadPdf('Reglamento.pdf')">Reglamento de convivencia </p></div>
        </div>
        <button :class="`PopUp-btn ${ acceptedPoliticas && acceptedReglamento ? '' : 'btn-locked' }`" @click="launchPopUp('IngresaTusDatos')" ref="restriccionesBtn">Aceptar</button>
    </div>
</template>

<script>
export default {
    name: 'PopUpRestricciones',
    data(){
        return{
            acceptedPoliticas: false,
            acceptedReglamento: false,
        }
    },
    methods:{
        launchPopUp(mode_){
            const btn = this.$refs.restriccionesBtn;
            btn.style.opacity = 0.5;
            btn.style.pointerEvents = "none";
            const titulo = this.productData.name;
            const costo = parseInt(this.productData.price.replaceAll(",", ""));
            localStorage.setItem("tituloYCosto", JSON.stringify({"titulo":titulo, "costo":costo}));

            this.$parent.launchPopUp(mode_);
        },

        getData(){
            this.productData = JSON.parse(localStorage.getItem("productData"));
            return this.productData;
        },

        async downloadPdf(doc_) {
            const doc = "https://sand-diamante.mx/documents/" + doc_;
            try {
                const response = await fetch(doc);
                if (!response.ok) {
                    throw new Error('Error al descargar el PDF');
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = doc_; // Puedes personalizar el nombre del archivo aquí
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error al descargar el PDF:', error);
            }
        }
    },

    mounted(){
        console.log(this.getData());
    }
}
</script>

<style scoped>
.PopUpRestricciones-MAIN{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.download-contract{
    color: #167AC0;
    font-weight: bolder;
    margin-bottom: 2ch;
    text-decoration: underline;
    cursor: pointer;
}

.restricciones-grid{
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: 100%;
    margin-bottom: 1ch;
}

.restricciones-grid > div{
    width: calc(100% - 1ch);
    padding: 0.5ch;
}

.restricciones-asistentes{
    font-weight: 600;
    font-size: 2ch;
    color: #167AC0;
}

.restricciones-asistentes span{
    color: gray;
    margin-left: 0.5ch;
    font-weight: 500;
}

.restricciones-title,
.restricciones-subtitle{
    display: flex;
    align-items: center;
}

.restricciones-title > img,
.restricciones-subtitle > img{
    max-height: 2ch;
    margin-right: 0.5ch;
}

.restricciones-subtitle{
    color: gray;
}

.checkboxes-info > div{
    display: flex;
    margin: 0.5ch;
}

.checkboxes-info p{
    margin: 0;
    color: #1D7ABE;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.checkboxes-info label{
    margin-right: 1ch;
}

.checkboxes-info input {
    width: 2ch;
    height: 2ch;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #167AC0;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkboxes-info input:checked {
    background-color: #167AC0;
}

.btn-locked{
    filter: grayscale(1);
    opacity: 0.4;
    pointer-events: none;
}
</style>