<template>
    <div class="SearchActive-MAIN">
        <div class="INNER-MARGIN">

            <SearchBar :searchData="searchData" class="search-bar-pc"/>
            <SearchBarCel :searchData="searchData" class="search-bar-cel"/>

        </div>
    </div>
</template>

<script>
import SearchBar from './SearchBar.vue';
import SearchBarCel from './SearchBarCel.vue';

export default {
    components: { SearchBar, SearchBarCel },
    props:{
        searchData: Object
    },
    methods:{
        setSearchData(data_){
            this.$parent.setSearchData(data_);
        }
    }
}
</script>

<style scoped>
.SearchActive-MAIN{
    font-family: 'Montserrat', sans-serif;
    background-color: #1D7ABE;
    padding-bottom: 3ch;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-bar-cel{
    display: none;    
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .search-bar-pc{
        display: none;
    }

    .search-bar-cel{
        margin-top: 3ch;
        display:flex;
    }

}
</style>