<template>
    <main class="ViewInfo-MAIN">
        
        <div class="ViewInfo-detalles-generales">
            <div class="INNER-MARGIN">
                <div class="close-btn" @click="closeInfo()">+</div>
                <h1>Detalles generales</h1>
                <h3>En Sand Diamante, buscamos brindar una experiencia única para elevar el estilo de vida de cada uno de los residentes a través de amenidades de primer nivel.</h3>
                <p> Ubicado en el corazón de Acapulco Diamante, este exclusivo complejo redefine la idea de hogar, ofreciendo  opciones distintas para satisfacer diferentes necesidades.</p>
            </div>
        </div>

        <div class="ViewInfo-amenidades-section">
            <!-- <img :src="productData.img"> -->
            <div>
                <div>
                    <PhotoAlbum :photos="photos"/>
                </div>
            </div>
            <div>
                <h2>Amenidades</h2>
                <h3>{{ productData.items.meters }}m<sup>2</sup></h3>
                <div class="ViewInfo-columns">
                    <ul v-html="sideL">
                    </ul>
                    <ul v-html="sideR">
                    </ul>
                     <!--<li><div></div> Prueba</li>-->
                </div>
            </div>
        </div>

    </main>
</template>

<script>
import PhotoAlbum from './PhotoAlbum.vue';

export default {
    name: 'ViewInfo',
    components: { PhotoAlbum }, 
    props: {
        productData: Object
    },
    data(){
        return{
            sideL: "",
            sideR: "",
            photos: [
                require("../assets/images/album0.png"),
                require("../assets/images/album1.png"),
                require("../assets/images/album2.png"),
                require("../assets/images/album3.png"),
                require("../assets/images/album4.png"),
                require("../assets/images/album5.png"),
                require("../assets/images/album6.png"),
                require("../assets/images/album7.png"),
                require("../assets/images/album8.png"),
                require("../assets/images/album9.png"),
                require("../assets/images/album10.png"),
                require("../assets/images/album11.png"),
                require("../assets/images/album12.png"),
                require("../assets/images/album13.png"),
            ],
        }
    },
    methods:{
        closeInfo(){
            this.$parent.closeInfo();
        }
    },
    mounted(){
        const array = this.productData.amenities;
        const midIndex = Math.floor(array.length / 2);
        const firstPart = array.slice(0, midIndex);
        const secondPart = array.slice(midIndex);

        for(let i of firstPart){
            this.sideL += `<li><div></div>${i}</li>`;
        }
        for(let i of secondPart){
            this.sideR += `<li><div></div>${i}</li>`;
        }
    }
}
</script>

<style scoped>
.ViewInfo-MAIN{
    width: 100%;
    margin-top: -12.4ch;
    z-index: 2;
}

.ViewInfo-detalles-generales{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/images/bg-info.png');
    background-size: cover;
    background-position: center;
    height: 60vh;
    font-size: 2ch;
}

.ViewInfo-detalles-generales .INNER-MARGIN{
   color: white;
   position: relative; 
   text-align: center;
}

.close-btn{
    font-size: 4ch;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 1ch;
    height: 1ch;
    position: absolute;
    right: 0;
    top: -1ch;
    transform: rotate(45deg);
    color: #152F62;
}

.close-btn:hover{
    cursor: pointer;
}

.ViewInfo-amenidades-section{
    display: flex;
    height: 60vh;
}

.ViewInfo-amenidades-section > *{
    display: flex;
    width: 50%;
    height: 100%;
}

.ViewInfo-amenidades-section > img{
    object-fit: cover;
}

.ViewInfo-amenidades-section > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4ch;
    width: calc(50% - 8ch);
    height: calc(100% - 8ch);
}

.ViewInfo-amenidades-section > div:nth-child(1) > div{
    width: 100%;
    max-width: 40ch;
    aspect-ratio: 1/1;
}

.ViewInfo-amenidades-section > div:nth-child(1) > div > *{
    margin-top: 33%;
}

.ViewInfo-amenidades-section h2{
    color: #1D7ABE;
    margin: 0;
    margin-top: -0.5ch;
    font-size: 3.3ch;
}

.ViewInfo-amenidades-section h3{
    margin-top: 1ch;
}

.ViewInfo-columns{
    display: flex;
}

.ViewInfo-columns ul{
    width: 50%;
    padding-left: 0;
}

.ViewInfo-columns ul > li, 
.ViewInfo-columns ul >>> li{
    display: flex;
    align-items: center;
    margin-bottom: 0.5ch;
}

.ViewInfo-columns ul > li > div,
.ViewInfo-columns ul >>> li > div{
    background-color: #46B2E8;
    height: 4px;
    width: 1.5ch;
    min-width: 1.5ch;
    margin-right: 1ch;
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .ViewInfo-detalles-generales{
        height: fit-content;
    }

    .ViewInfo-detalles-generales h1{
        font-size: 8vw;
        margin-top: 18vw;
    }

    .ViewInfo-detalles-generales h3, .ViewInfo-detalles-generales p{
        font-size: 4.5vw;
    }

    .ViewInfo-detalles-generales h3{
        font-weight: 500;
    }

    .ViewInfo-detalles-generales p{
        margin-bottom: 3ch;
    }

    .close-btn{
        top: 0.1ch;
        font-size: 20vw;
    }

    .ViewInfo-amenidades-section{
        flex-direction: column;
        align-items: center;
        height: fit-content;
    }

    .ViewInfo-amenidades-section > div:nth-child(1) > div > *{
        margin-top: 50%;
    }

    .ViewInfo-amenidades-section > div:nth-child(1){
        margin-top: 6ch;
        margin-bottom: 4ch;
    }

    .ViewInfo-amenidades-section > img{
        width: 100%;
    }

    .ViewInfo-amenidades-section > *:not(img){
        width: 80% !important;
        padding: 0 !important;
        margin-top: 3ch;
        margin-bottom: 2ch;
        height: unset;
    }

    .ViewInfo-amenidades-section h2{
        font-size: 10vw;
    }

    .ViewInfo-amenidades-section h3{
        font-size: 5.2vw;
    }

    .ViewInfo-columns{
        flex-direction: column;
    }

    .ViewInfo-columns ul{
        width: 100%;
        margin: 0;
        font-size: 4.5vw !important;
    }

}
</style>