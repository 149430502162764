<template>
    <div class="DatosBancarios-MAIN">

        <h1 class="PopUp-title">DATOS BANCARIOS</h1>

        <section>
            <p><img src="../../assets/images/user.svg">Nombre que figura en la tarjeta*</p>
            <div><input type="text" ref="cardNames" placeholder="Nombre(s) Apellido paterno Apellido materno" required></div>
            <p><img src="../../assets/images/credit-card.svg">Número de la tarjeta*</p>
            <div><input type="tel" inputmode="numeric" pattern="[0-9\s]{13,19}" autocomplete="cc-number" maxlength="19" placeholder="0000  1234  4567  8900" ref="cardNum" required></div>
            <div class="DatosBancarios-division">
                <div>
                    <p><img src="../../assets/images/calendar.svg">Fecha de vencimiento*</p>
                    <div><input type="text" placeholder="MM / AA" ref="cardExpiration" required></div>
                </div>
                <div>
                    <p><img src="../../assets/images/cvc.svg">CVC*</p>
                    <div><input type="tel" inputmode="numeric" pattern="[0-9\s]{13,19}" maxlength="5" placeholder="123" ref="cardCvc" required></div>
                </div>
            </div>
        </section>

        <button class="PopUp-btn" @click="payment()">PAGAR</button>
    </div>
</template>

<script>
export default {
    name: 'DatosBancarios',
    data(){
        return{
            
        }
    },
    methods:{
        launchPopUp(mode_){
            this.$parent.launchPopUp(mode_);
        },

        payment(){
            const card = {
                names: this.$refs.cardNames.value,
                num: this.$refs.cardNum.value,
                expiration: this.$refs.cardExpiration.value,
                cvc: this.$refs.cardCvc.value
            };
            console.log(card);

            //No en uso
            fetch('https://www.sand-diamante.mx/api.php/mercadopago/preference', {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => response.json())
            .then(json => {
                console.log(json)

                if(json.error == 0 || json.error == "0"){
                    this.launchPopUp("PagoExitoso");
                }else{
                    this.launchPopUp("PagoError");
                }
            })
        }
    }
}
</script>

<style scoped>
.DatosBancarios-MAIN{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 50ch;
}

section{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #152F62;
    width: 100%;
}

section p{
    font-weight: 600;
    margin-bottom: 0.5ch;
    width: 80%;
    position: relative;
}

section  div{
    display: flex;
    align-items: center;
    position: relative;
    width: 80%;
}

section p img{
    position: absolute;
    max-width: 2ch;
    left: -3ch;
}

section input{
    padding: 0.8ch;
    border-radius: 5px;
    border: 1px solid gray;
    width: 100%;
}

section button{
    margin-top: 4ch;
}

.DatosBancarios-division{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    justify-self: flex-end;
}

.DatosBancarios-division > div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 40%;
    padding: 0 !important;
}

.DatosBancarios-division p{
    margin-left: 0;
    white-space: nowrap;
}

button{
    margin-top: 4ch;
}
</style>