<template>
    <div class="EspacioIdeal-MAIN">
        <div class="INNER-MARGIN">

            <div class="EspacioIdeal-l" data-aos="fade-right">       
                <p>Elige tu</p>
                <h2>ESPACIO IDEAL</h2>
                <p>
                    <br>
                    Relájate con toda la familia en este alojamiento donde la tranquilidad se respira.
                    <br><br>
                    Todos los departamentos cuenta con 2 habitaciones con aire acondicionado, 2 baños, Wifi y estacionamiento.
                    <br><br>Elige entre 104 m2 con 2 terrazas o 122 m2 con 3 terrazas, todas con vista a la alberca.
                    <br><br>
                    Podrás disfrutar de la playa que tenemos a sólo 5 minutos en automóvil. 
                    <br><br>
                    <span><b>Y próximamente tendremos disponible para ti acceso al club de playa privado.</b></span>
                    <br><br>
                    Siéntete seguro, contamos con vigilancia controlada las 24 hrs.
                    <br><br>
                </p>
                <button @click="goToSearch()">RESERVA AHORA</button>
            </div>

            <div class="EspacioIdeal-r" data-aos="fade-left">
                <PhotoAlbum :photos="photos"/>
            </div>

            <!--<div class="EspacioIdeal-tiles">
                <div v-for="(tile, index) in tileInfo" :key="index" class="">
                    <img :src="tile.img"/>  
                    <p class="EspacioIdeal-tile-title">{{ tile.name }}</p>
                    <p><img src="../assets/images/icon-location.svg">{{ tile.meters }} m<sup>2</sup></p>
                    <div>
                        <p><img src="../assets/images/icon-bed.svg">{{ tile.rooms }} habitaciones</p>
                        <p><img src="../assets/images/icon-bath.svg">{{ tile.bathrooms }} baños</p>
                        <p><img src="../assets/images/icon-table.svg">{{ tile.terrazas }} terrazas</p>
                        <p><img src="../assets/images/icon-car.svg">{{ tile.estacionamiento }} estacionamiento</p>
                    </div>
                </div>
            </div>-->

        </div>
    </div>
</template>

<script>
import PhotoAlbum from './PhotoAlbum.vue';
export default {
  components: { PhotoAlbum },
    name: 'EspacioIdeal',
    data(){
        return{
            tileInfo:[
                {
                    name: "Departamento equipado lateral",
                    meters: 105.75,
                    img: require('../assets/images/dep-lateral.png'),
                    rooms: "2",
                    bathrooms: "2",
                    terrazas: "2",
                    estacionamiento: "1"
                },
                {
                    name: "Departamento equipado central",
                    meters: 118.5,
                    img: require('../assets/images/dep-central.png'),
                    rooms: "2",
                    bathrooms: "2",
                    terrazas: "3",
                    estacionamiento: "1"
                },
                /*{
                    name: "Penthouse lateral",
                    meters: 216.5,
                    img: require('../assets/images/pen-lateral.png'),
                    rooms: "3",
                    bathrooms: "2½",
                    terrazas: "2",
                    estacionamiento: "1"
                },
                {
                    name: "Penthouse CENTRAL",
                    meters: 227.7,
                    img: require('../assets/images/pen-central.png'),
                    rooms: "2",
                    bathrooms: "2½",
                    terrazas: "3",
                    estacionamiento: "1"
                },*/
            ],
            photos: [
                require("../assets/images/album0.png"),
                require("../assets/images/album1.png"),
                require("../assets/images/album2.png"),
                require("../assets/images/album3.png"),
                require("../assets/images/album4.png"),
                require("../assets/images/album5.png"),
                require("../assets/images/album6.png"),
                require("../assets/images/album7.png"),
                require("../assets/images/album8.png"),
                require("../assets/images/album9.png"),
                require("../assets/images/album10.png"),
                require("../assets/images/album11.png"),
                require("../assets/images/album12.png"),
                require("../assets/images/album13.png"),
            ],
        }
    },

    methods:{
        goToSearch(){
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style scoped>
.EspacioIdeal-MAIN{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F0F0F0;
    font-family: 'Montserrat', sans-serif;
    padding-top: 2ch;
    padding-bottom: 4ch;
}

.INNER-MARGIN{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
}

.EspacioIdeal-MAIN .INNER-MARGIN{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EspacioIdeal-l{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
}

.EspacioIdeal-l > *:not(h2):not(p:nth-child(1)){
    text-align: left;
    text-wrap: balance !important;
}

.EspacioIdeal-l> p{
    font-size: 1.7ch;
    margin: 0.5ch;
    text-align: center;
}

.EspacioIdeal-l > p:nth-child(1){
    font-size: 2.2ch;
    font-weight: 600;
    color: #003567;
}

.EspacioIdeal-l span{
    color: #167AC0;
}

h2{
    color: #167AC0;
    margin: 0;
    margin-bottom: 0.2ch;
    font-size: 3ch;
}

.EspacioIdeal-r{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* tiles */
/*.EspacioIdeal-tiles{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 3ch;
    margin-bottom: 4.3ch;
}

.EspacioIdeal-tiles > div{
    width: calc(23.5% * 2);
    background-color: white;
    
    box-shadow: 0px 0px 1ch rgba(46, 46, 46, 0.2);
}

.EspacioIdeal-tiles > div > img{
    object-fit: cover;
    object-position: center;
    width: 100%;*/
    /*aspect-ratio: 1/1.1;*/
    /*aspect-ratio: 1/0.5;
}

.EspacioIdeal-tiles > div > p:not(.EspacioIdeal-tile-title){
    margin-left: 0.5ch;
}

.EspacioIdeal-tiles > div p{
    padding: 0 1ch;
    margin: 0.5ch 0;
    font-size: 1.2ch;
    font-weight: 500;
}

.EspacioIdeal-tiles > div p img{
    margin-right: 0.5ch;
}

.EspacioIdeal-tiles > div div{
    display: grid;
    grid-template-columns: 45% 45%;
    grid-template-rows: 50% 50%;
    white-space: nowrap;
    margin-top: 1.2ch;
    margin-bottom: 0.8ch;
    margin-left: 0.3ch;
}

.EspacioIdeal-tile-title{
    margin-top: 0.5ch;
    margin-bottom: 1ch !important;
    font-weight: bold !important;
    color: #167AC0;
    white-space: nowrap;
    font-size: 1.5ch !important;
}*/

button{
    height: 6ch;
    border: none;
    padding: 0 2ch;
    background-color: #BEAF94;
    font-weight: 600;
    color: white;
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .EspacioIdeal-MAIN .INNER-MARGIN > p:nth-child(1){
        font-size: 8vw;
        margin-top: 0;
    }

    .INNER-MARGIN{
        flex-direction: column !important;
    }

    .EspacioIdeal-l, .EspacioIdeal-r{
        width: 100%;
    }

    .EspacioIdeal-r{
        height: 100vw;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .EspacioIdeal-r > *{
        margin-top: 65%;
    }

    h2{
        font-size: 10vw;
    }

    .EspacioIdeal-MAIN .INNER-MARGIN > p:nth-child(3){
        font-size: 5vw;
    }

    .EspacioIdeal-MAIN .INNER-MARGIN > p:nth-child(3) br{
        display: none;
    }

    /* tiles */
    /*.EspacioIdeal-tiles{
        flex-direction: column;
        align-items: center;
    }

    .EspacioIdeal-tiles > div{
        width: 100%;
        margin-bottom: 6vw;
    }

    .EspacioIdeal-tile-title{
        font-size: 5vw !important;
    }

    .EspacioIdeal-tiles p:not(.EspacioIdeal-tile-title){
        font-size: 4.2vw;
    }

    .EspacioIdeal-tiles > div > img{
        aspect-ratio: 2/1;
    }

    .EspacioIdeal-tiles > div div{
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }*/

    button{
        margin-bottom: 4vw;
    }

}
</style>