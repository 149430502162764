<template>
    <div class="PhotoAlbum-MAIN">
    
        <section>
            <button @click="changePhoto(-1)" ref="btnLeft" class="btn-left"><img src="../assets/images/arrow-album.png"></button>
            <button @click="changePhoto(+1)" ref="btnRight" class="btn-right"><img src="../assets/images/arrow-album.png"></button>
            <img src="../assets/images/zoom-in.png" @click="setImg()" ref="zoomIn" class="zoom-in">
            <img v-for="(photo, index) in photos" :key="index" :src="photo" alt="Photo"
                :class="`${definePhotoClass(index)}`"
                :style="`
                    z-index:${defineZIndex(index)};
                    scale: 0.9${defineZIndex(index)};
                `"
            />
        </section>

        <div v-if="viewerActive" class="viewerActive">
            <div @click="viewerActive = false"></div>
            <img ref="imgViewer">
        </div>

    </div>
</template>

<script>
export default {
    name: 'PhotoAlbum',
    props:{
        photos: Array
    },
    data(){
        return{
            maxPhotos: this.photos.length,
            currentPhoto: Math.ceil(this.photos.length/2)+3,
            viewerActive: false,
        }
    },
    methods:{
        changePhoto(direction_){
            if(direction_ < 0 && this.currentPhoto <= 0){
                return;
            }
            if(direction_ > 0 && this.currentPhoto >= this.maxPhotos-1){
                return;
            }
            this.currentPhoto += direction_;
        },

        definePhotoClass(id_){
            if(id_ < this.currentPhoto){
                return "left-photo";
            }else
            if(id_ == this.currentPhoto){
                return "central-photo";
            }else
            if(id_ > this.currentPhoto){
                return "right-photo";
            }
        },

        defineZIndex(id_){
            if(id_ <= this.currentPhoto){
                return id_ + 1;
            }else{
                const result = this.currentPhoto+(this.currentPhoto-id_);
                if(result <= 0){
                    return 0;
                }else{
                    return this.currentPhoto+(this.currentPhoto-id_); 
                }
            }
        },

        setImg(){
            const id = this.currentPhoto;
            this.viewerActive = true;
            this.$nextTick(() => {
                this.$refs.imgViewer.src = this.photos[id];
            });
        }

    },

    mounted(){
        this.$refs.btnLeft.style.zIndex = this.maxPhotos+1;
        this.$refs.btnRight.style.zIndex = this.maxPhotos+1;
        this.$refs.zoomIn.style.zIndex = this.maxPhotos+1;
    }
}
</script>

<style scoped>
.PhotoAlbum-MAIN {
    width: 100%;
    height: 100%;
}

.PhotoAlbum-MAIN > section{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PhotoAlbum-MAIN > section > img{
    position: absolute !important;
    border: 5px solid whitesmoke;
    transition: all 0.2s;
}

.central-photo{
    width: 80%;
}

.left-photo, .right-photo{
    width: 60%;
}

.left-photo{
    margin-right: 38%;
}

.right-photo{
    margin-left: 38%;
}

.PhotoAlbum-MAIN > section > button{
    background-color: transparent;
    width: 7ch;
    height: 7ch;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border: none;
    border-radius: 100vw;
    box-shadow: 0 0 4ch rgba(0,0,0,0.5);
}

.PhotoAlbum-MAIN > section > button > img{
    width: 100% !important;
    height: 100% !important;
    max-width: unset !important;
    position: absolute;
}

.btn-left{
    left: 8%;
}

.btn-right{
    right: 8%;
    rotate: 180deg;
}

.zoom-in{
    border: none !important;
    margin-top: 90%;
    width: 4.5ch;
    cursor: pointer;
}

.viewerActive{
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.viewerActive > div{
    width: 100%;
    height: 100%;
    cursor: zoom-out;
}

.viewerActive > img{
    max-width: 80%;
    max-height: 80%;
    position: absolute;
    box-shadow: 0 0 2ch rgba(0,0,0,0.3);
}
</style>