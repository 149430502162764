<template>
    <div class="ReservaEstancia-MAIN">
        <div class="INNER-MARGIN">

            <h2>
                ¡Reserva tu estancia y disfruta de lo mejor de Acapulco Diamante!
                <br><span>Contamos con las mejores amenidades para ti</span>
            </h2>

            <h2 class="cel-name">{{ amenityData[selectedAmenity]?.name }}</h2>

            <div class="amenidades-tiles" data-aos="fade-right">
                <div @click="viewTile('Alberca')" ref="tileAlberca"><div><img src="../assets/images/iconAlberca.svg" ref="tileImgAlberca"></div><p ref="tileTextAlberca"><br>Alberca</p></div>
                <div @click="viewTile('Gimnasio')" ref="tileGimnasio"><div><img src="../assets/images/iconGimnasio.svg" ref="tileImgGimnasio"></div><p ref="tileTextGimnasio"><br>Gimnasio</p></div>
                <div @click="viewTile('Jardines')" ref="tileJardines"><div><img src="../assets/images/iconJardines.svg" ref="tileImgJardines"></div><p ref="tileTextJardines"><br>Jardines</p></div>
                <!--<div @click="viewTile('Reuniones')" ref="tileReuniones"><div><img src="../assets/images/iconSalaReuniones.svg" ref="tileImgReuniones"></div><p ref="tileTextReuniones">Sala de<br>Reuniones</p></div>-->
                <div @click="viewTile('PetPark')" ref="tilePetPark"><div><img src="../assets/images/iconPetPark.svg" ref="tileImgPetPark"></div><p ref="tileTextPetPark"><br>Pet Park</p></div>
                <div @click="viewTile('Terrazas')" ref="tileTerrazas"><div><img src="../assets/images/iconTerrazas.svg" ref="tileImgTerrazas"></div><p ref="tileTextTerrazas"><br>Terrazas</p></div>
                <div @click="viewTile('Seguridad')" ref="tileSeguridad"><div><img src="../assets/images/iconSeguridad.svg" ref="tileImgSeguridad"></div><p ref="tileTextSeguridad"><br>Seguridad</p></div>
            </div>

            <div class="amenidades-data" data-aos="fade-left">
                <h2>{{ amenityData[selectedAmenity]?.name }}</h2>
                <p>{{ amenityData[selectedAmenity]?.content }}</p>

                <h3>Ven a vivir Sand diamante</h3>
                <div>
                    <img :src="amenityData[selectedAmenity]?.images[0]">
                    <img :src="amenityData[selectedAmenity]?.images[1]">
                    <img :src="amenityData[selectedAmenity]?.images[2]">
                </div>
            </div>

            <button @click="goToSearch()">RESERVA HOY MISMO</button>

        </div>
    </div>
</template>

<script>
export default {
    name: 'ReservaEstancia',
    data(){
        return{
            amenityData:{
                "Alberca": {
                    name: "Alberca",
                    content: "Contamos con más de 6 mil m2 de área común, dos albercas recreativas donde podrás disfrutar con tu familia y amigos o para hacer ejercicio.",
                    images: [require("../assets/images/Alberca1.webp"), require("../assets/images/Alberca2.webp"), require("../assets/images/Alberca3.webp")]
                },
                "Gimnasio": {
                    name: "Gimnasio",
                    content: "Ejercítate en nuestro gimnasio totalmente equipado. Podrás hacer uso de esta amenidad cuando más se te acomode. Contamos con máquinas de última generación y con instructores comprometidos a llevarte al próximo nivel.",
                    images: [require("../assets/images/Gimnasio1.webp"), require("../assets/images/Gimnasio2.webp"), require("../assets/images/Gimnasio3.webp")]
                },
                "Jardines": {
                    name: "Jardines",
                    content: "Disfruta de nuestras áreas verdes que te ayudarán a encontrar la paz y armonía que necesitas. Da largos paseos con el excelente clima de Acapulco Diamante, diviértete en familia en nuestros carriles de entrenamiento y disfruta de nuestros jardines en todo momento.",
                    images: [require("../assets/images/Jardines1.webp"), require("../assets/images/Jardines2.webp"), require("../assets/images/Jardines3.webp")]
                },
                "Reuniones": {
                    name: "Sala de Reuniones",
                    content: "Ten la practicidad de descansar y atender al trabajo en caso de que lo necesites con nuestra sala de reuniones. Tómate un descanso y disfruta de Acapulco Diamante mientras pones a punto todas tus metas laborales.",
                    images: [require("../assets/images/Reuniones1.webp"), require("../assets/images/Reuniones2.webp"), require("../assets/images/Reuniones3.webp")]
                },
                "PetPark": {
                    name: "Pet Park",
                    content: "Porque las comodidades tienen que ser para todos. Contamos con espacios amistosos para tus animales de compañía. Tus mascotas podrán disfrutar de un ambiente seguro y adecuado en nuestros departamentos y áreas asignadas en Acapulco Diamante.",
                    images: [require("../assets/images/PetPark1.webp"), require("../assets/images/PetPark2.webp"), require("../assets/images/PetPark3.webp")]
                },
                "Terrazas": {
                    name: "Terrazas",
                    content: "Experimenta lo mejor del clima y ambiente de Acapulco Diamante con nuestras terrazas privadas completamente equipadas, para que te diviertas con familia y amigos. Cada Departamento y Penthouse cuenta con espacios que podrás utilizar cuando lo requieras.",
                    images: [require("../assets/images/Terrazas1.webp"), require("../assets/images/Terrazas2.webp"), require("../assets/images/Terrazas3.webp")]
                },
                "Seguridad": {
                    name: "Seguridad",
                    content: "Y para tu tranquilidad, contamos con un sistema de seguridad con cámaras de circuito cerrado, las cuales se encargan de resguardar tu bienestar y el de tus seres queridos. Te cuidamos las 24 horas, los 7 días de la semana.",
                    images: [require("../assets/images/Seguridad1.webp"), require("../assets/images/Seguridad2.webp"), require("../assets/images/Seguridad3.webp")]
                }
            },
            selectedAmenity: ""
        }
    },
    
    methods:{
        viewTile(tile_){
            this.selectedAmenity = tile_;
            const amenidades = ["Alberca", "Gimnasio", "Jardines", /*"Reuniones",*/ "PetPark", "Terrazas", "Seguridad"];
            for(let i of amenidades){
                this.$refs["tile"+i].style.backgroundColor = "#EFEFEF";
                this.$refs["tile"+i].style.justifyContent = "flex-end";
                this.$refs["tileText"+i].style.display = "inline";
                this.$refs["tileImg"+i].style.filter = "grayscale(1) brightness(1.7)";
            }
            this.$refs["tile"+tile_].style.backgroundColor = "white";
            this.$refs["tile"+tile_].style.justifyContent = "center";
            this.$refs["tileText"+tile_].style.display = "none";
            this.$refs["tileImg"+tile_].style.filter = "grayscale(0) brightness(1)";
        },

        goToSearch(){
            window.scrollTo(0, 0);
        }
    },

    mounted(){
        this.$refs["tileGimnasio"].click();
    }
}
</script>

<style scoped>
.ReservaEstancia-MAIN{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

.ReservaEstancia-MAIN .INNER-MARGIN{
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2{
    text-align: center;
    color: #003567;
    margin-top: 3ch;
    margin-bottom: 3ch;
}

h2 span, .amenidades-data h2{
    color: #167AC0;
    margin-bottom: 0;
}

/* tiles */
.amenidades-tiles{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.amenidades-tiles > div{
    width: calc((100% / 7) - 1%);
    aspect-ratio: 1/1;
    background-color: #EFEFEF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    position: relative;
    transition: all 0.2s;
}

.amenidades-tiles > div:hover{
    cursor: pointer;
}

.amenidades-tiles > div > div{
    width: 40%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.amenidades-tiles > div > div img{
    max-width: 100%;
    max-height: 100%;
    filter: grayscale(1) brightness(1.7);
    transition: all 0.2s;
}

.amenidades-tiles p{
    margin-top: 0.5ch;
    font-weight: bold;
    color: #B0B0B0;
}

/* data */
.amenidades-data{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.amenidades-data *{
    text-align: left;
}

.amenidades-data > div{
    display: flex;
    justify-content: space-between;
}

.amenidades-data > div img{
    width: 30%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
}

h3{
    color: #003567;
    font-weight: 500;
}

.INNER-MARGIN > button{
    height: 6ch;
    border: none;
    padding: 0 2ch;
    font-weight: 600;
    background-color: #167AC0;
    color: white;
    margin: 6ch 0;
}

.cel-name{
    display: none;
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .INNER-MARGIN > h2:nth-child(1){
        display: none;
    }

    .cel-name{
        display: flex;
        margin: 0;
        margin-top: 7vw;
        font-size: 8vw;
        color: #167AC0;
    }

    .amenidades-tiles{
        overflow: auto; 
        margin-left: -5%;   
        width: 110%;
        margin-top: 7vw;
    }

    .amenidades-tiles > div{
        aspect-ratio: 1/1 !important;
        min-width: 30vw !important;
        width: 30vw !important;
        display: flex;
    }

    .amenidades-tiles > div{
        margin-left: 2vw;
    }

    .amenidades-data > div{
        display: grid;
        grid-template-rows: 47% 47%;
        grid-template-columns: 47% 47%;
        align-content: center;
        justify-content: center;
        align-self: center;
        grid-gap: 4vw;
    }

    .amenidades-data > div img{
        width: 100%;
    }

}
</style>