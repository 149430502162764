<template>
    <div class="NotAvailable-MAIN">
        <img src="../../assets/images/calendario.svg"/>
        <h1 class="available-title PopUp-title">NO DISPONIBLE</h1>
        <p>Lo sentimos, en este momento no tenemos disponibilidad <br>para las fechas que buscas</p>
        <p>Intenta con otras fechas</p>
        <button @click="closePopUp()">Aceptar</button>
    </div>
</template>

<script>
export default {
    name: 'NotAvailable',
    methods:{
        closePopUp(){
            this.$parent.closePopUp();
        }
    }
}
</script>

<style scoped>
.NotAvailable-MAIN{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.NotAvailable-MAIN img{
    width: 4.5ch;
}

p{
    text-align: center;
}

.NotAvailable-MAIN > p:nth-child(3){
    font-size: 1.3ch;
}

button{
    height: 5ch;
    border: none;
    padding: 0 1ch;
    background-color: #167AC0;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: white;
    width: 15ch;
    min-width: 40%;
    text-transform: uppercase;
    margin-top: 2ch;
}
</style>