<template>
    <div class="CoverSearch-MAIN">

        <ImgSlider :imgList="sliderImages" :key="keyUpdater"/>

        <div class="INNER-MARGIN CoverSearch-content">
            <h1>Un Oasis en el Corazón de <br><b>Acapulco Diamante</b></h1>
            <p>Descubre la versatilidad en nuestras opciones de alquiler: <br><b>desde unos días hasta un año entero, tu estadía se adapta a ti  en Acapulco Diamante.</b></p>
            <SearchBar :searchData="searchData" class="search-bar-pc"/>
            <SearchBarCel :searchData="searchData" class="search-bar-cel"/>
        </div>

    </div>
</template>

<script>
import ImgSlider from './ImgSlider.vue';
import SearchBar from './SearchBar.vue';
import SearchBarCel from './SearchBarCel.vue';

export default {
    name: 'CoverSearch',
    props:{
        searchData: Object
    },
    data() {
        return {
            sliderImages: [
                require('../assets/images/bg1.png'),
                require('../assets/images/bg2_a.png'),
                require('../assets/images/bg3_a.png'),
                require('../assets/images/bg4_a.png'),
            ],
            keyUpdater: 0,
            prevWidth: window.innerWidth,
            resizeTimeout: null,
        };
    },
    components: { ImgSlider, SearchBar, SearchBarCel },

    methods: {
        handleResize() {
            if (window.innerWidth !== this.prevWidth) {
                this.keyUpdater += 1;
                this.prevWidth = window.innerWidth;
            }
        },
        debouncedHandleResize() {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(this.handleResize, 200); // 200 ms debounce time
        },
        setSearchData(data_) {
            this.$parent.setSearchData(data_);
        }
    },

    mounted() {
        window.addEventListener('resize', this.debouncedHandleResize);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.debouncedHandleResize);
    },
}
</script>

<style scoped>
.CoverSearch-MAIN{
    height: 50ch;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

.CoverSearch-content{
    position: absolute;
    color: white;
    text-align: center;
}

h1{
    font-weight: 500;
    margin-bottom: 0;
    font-size: 3.5ch;
    text-shadow: 0ch 0ch 0.5ch rgba(0, 0, 0, 0.5);
}

.search-bar-cel{
    display: none;    
}

/* RESPONSIVE */
@media all and (max-width: 600px) {
  
    .CoverSearch-MAIN{
        height: 120vw;
    }

    .CoverSearch-content{
        max-width: unset;
        width: 98%;
    }

    h1{
        margin: 0;
        font-size: 6.8vw;
    }

    p{
        font-size: 4.5vw;
    }

    p br{
        display: none;
    }

    .search-bar-pc{
        display: none;
    }

    .search-bar-cel{
        display: flex;
    }

}
</style>