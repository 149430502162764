<template>
    <div class="ProcesoExitoso-MAIN">

        <img :src="type == 'RegistroExitoso' ? require('../../assets/images/registro-exitoso.png') : require('../../assets/images/pago-exitoso.png')">

        <div>
            <img :src="type == 'RegistroExitoso' ? require('../../assets/images/comprobado.svg') : require('../../assets/images/schedule.svg')">
            <h1 class="PopUp-title">{{ title }}</h1>
            <p>{{ text }}</p>
            <div v-if="type == 'RegistroExitoso'"><img src="../../assets/images/whatsapp_blue.svg">5590302490</div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ProcesoExitoso',
    props:{
        type: String,
        title: String,
        text: String
    },
    methods:{
        //https://www.sand-diamante.mx/landing_renta/?msg=PagoExitoso&collection_id=1322910313&collection_status=approved&payment_id=1322910313&status=approved&external_reference=null&payment_type=debit_card&merchant_order_id=18167885720&preference_id=677910714-dc0e6cf0-f0fe-4bf8-82a0-6d7eb0e48fa3&site_id=MLM&processing_mode=aggregator&merchant_account_id=null
        sendTransactionData(){
            const searchData = JSON.parse(localStorage.getItem("searchData"));
            const retrievedUserData = JSON.parse(localStorage.getItem("retrievedUserData"));
            const productData = JSON.parse(localStorage.getItem("productData"));
            const url = window.location.href;
            console.log("productData", productData)
            const dataToSend = {
                "usuario_id": retrievedUserData.id.toString(),
                "departamento_id": productData.id.toString(),
                "monto": parseFloat(productData.price.replaceAll(",", "")).toString(),
                "msg": "PagoExitoso",
                "collection_id": this.getURLparameter(url, 'colection_id') !== null ? this.getURLparameter(url, 'colection_id') : "null",
                "collection_status": this.getURLparameter(url, 'collection_status').toString(),
                "payment_id": this.getURLparameter(url, 'payment_id').toString(),
                "status": this.getURLparameter(url, 'status').toString(),
                "external_reference": this.getURLparameter(url, 'external_reference').toString(),
                "payment_type": this.getURLparameter(url, 'payment_type').toString(),
                "merchant_order_id": this.getURLparameter(url, 'merchant_order_id').toString(),
                "preference_id": this.getURLparameter(url, 'preference_id').toString(),
                "site_id": this.getURLparameter(url, 'site_id').toString(),
                "processing_mode": this.getURLparameter(url, 'processing_mode').toString(),
                "merchant_account_id": this.getURLparameter(url, 'merchant_account_id').toString(),
                "fecha_inicial": searchData.arrival.toString(),
                "fecha_final": searchData.exit.toString(),

                "usuario_nombre": retrievedUserData.nombre + " " + retrievedUserData.apellidos,
                "usuario_correo": retrievedUserData.email,
                "usuario_telefono": retrievedUserData.telefono,
            };
            
            fetch('https://admin.sand-diamante.mx/api/transaccion', {
                method: 'POST',
                body: JSON.stringify(dataToSend),
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => response.json())
            .then(json => {
                console.log(json)
                if(json.error == 0){
                    console.log("Ok");
                    setTimeout(() => {
                        localStorage.clear();
                    }, 1000);
                }
            });
        },

        getURLparameter(url, parametro) {
            const queryString = url.split('?')[1];
            const parametros = queryString.split('&');
            for (let i = 0; i < parametros.length; i++) {
                const parametroActual = parametros[i].split('=');
                if (parametroActual[0] === parametro) {
                    return parametroActual[1];
                }
            }
            return null;
        }
    },
    mounted(){
        const url = window.location.href;
        if(url.includes("PagoExitoso") || url.includes("approved")){
            console.log("Registrando transaccion.");
            //this.sendTransactionData();
        }else{
            console.log("Ocurrio un error. Los datos en localStorage no seran borrados.");
        }
    }
}
</script>

<style scoped>
.ProcesoExitoso-MAIN{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2ch;
}

.ProcesoExitoso-MAIN h1{
    margin-top: 0.5ch;
    margin-bottom: 0.5ch;
    white-space: nowrap;
}

.ProcesoExitoso-MAIN > img{
    width: 30ch;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.ProcesoExitoso-MAIN > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25ch;
    margin-left: 4ch;
}

.ProcesoExitoso-MAIN > div img{
    max-width: 8ch;
    max-height: 8ch;
}

.ProcesoExitoso-MAIN > div > div{
    display: flex;
    align-items: center;
    margin-top: 1ch;
}

.ProcesoExitoso-MAIN > div > div img{
    max-width: 3ch;
    max-height: 3ch;
    margin-right: 1ch;
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .ProcesoExitoso-MAIN{
        flex-direction: column;
        align-items: center;
    }

    .ProcesoExitoso-MAIN > img{
        aspect-ratio: 1/1 !important;
        width: 80% !important;
    }

    .ProcesoExitoso-MAIN > div > img{
        margin-top: 2ch;
    }
    
}
</style>