<template>
    <div class="TuViaje-MAIN">
        <h1 class="PopUp-title">TU VIAJE</h1>

        <div class="TuViaje-columns">
            <div>
                <p class="TuViaje-title">Departamento equipado lateral</p>

                <p>
                    Fechas:<br>
                    <span>5 - 10  de Febrero</span>
                </p>

                <p>
                    Huéspedes:<br>
                    <span>3 Huéspedes</span>
                </p>
            </div>
            <div>
                <p class="TuViaje-title">Detalles del precio</p>

                <table>
                    <tr>
                        <td><!--$2,586<span>x 3 noches</span>--></td>
                        <td><!--$7,758-->$15</td>
                    </tr>
                    <tr>
                        <td>Impuestos</td>
                        <td>$<!--1,241-->0</td>
                    </tr>
                    <tr>
                        <td>ISH</td>
                        <td>$<!--232-->0</td>
                    </tr>
                    <tr>
                        <th class="total">Total<span>(MXN)</span></th>
                        <th class="total"><!--$3,000-->$15</th>
                    </tr>
                </table>
            </div>
        </div>

        <!--<button class="PopUp-btn" @click="payment()">Pagar</button>-->
        <p class="download-contract" @click="downloadPdf('Contrato.pdf')">Descargar contrato</p>
        <div id="payment-sdk-button"></div>
    </div>
</template>

<script>
import { loadMercadoPago } from "@mercadopago/sdk-js";

export default {
    name: 'TuViaje',
    data(){
        return{
            productData: {}
        }
    },
    methods:{
        launchPopUp(mode_){
            this.$parent.launchPopUp(mode_);
        },

        async payment(){
            console.log("loadMercadoPago():");
            await loadMercadoPago();
            console.log("loadMercadoPago() ready");
            //const mp = new window.MercadoPago('TEST-2cd17907-15a1-4215-9fb1-a70f79e7f314');
            const mp = new window.MercadoPago('APP_USR-dbeac7cb-cf8b-4ba1-912c-520bb304c89e');
            console.log("new window.mercadoPago")
            // eslint-disable-next-line
            const checkout = mp.checkout({
                preference: {
                    id: localStorage.getItem('preferenceId')
                },
                render:{
                    container: '#payment-sdk-button',
                    label: 'Pagar'
                }
            });

            setTimeout(() => {
                try {
                    console.log("Change className button");
                    document.querySelector(".mercadopago-button").className = "PopUp-btn";
                } catch (error) {
                    console.log("error");
                }
            }, 80);
        },

        getData(){
            this.productData = JSON.parse(localStorage.getItem("productData"));
            return this.productData;
        },

        async downloadPdf(doc_) {
            const doc = "https://sand-diamante.mx/documents/" + doc_;
            try {
                const response = await fetch(doc);
                if (!response.ok) {
                    throw new Error('Error al descargar el PDF');
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = doc_; // Puedes personalizar el nombre del archivo aquí
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error al descargar el PDF:', error);
            }
        }
    },
    mounted(){
        this.payment();
    }
}
</script>

<style scoped>
.TuViaje-MAIN{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.TuViaje-columns{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2ch;
}

.TuViaje-columns > div{
    width: 45%;
}

.TuViaje-title{
    font-weight: bold;
    color: #152F62;
    margin-bottom: 3.5ch;
}

.TuViaje-columns > div p:not(.TuViaje-title) {
    line-height: 3ch;
}

.TuViaje-columns > div p span{
    color: gray;
}

table{
    font-size: 2ch;
    border-spacing: 1ch;
    color: #152F62;
    margin-left: -1ch;
    margin-top: -1ch;
}

table span{
    font-size: 1ch;
    font-weight: bold;
    color: #1D7ABE;
    margin-left: 0.5ch;
    white-space: nowrap;
}

th{
    text-align: left;
}

th span{
    color:#152F62;
}

.total{
    border-top: 1px solid #1d7bbe42;
    padding-top: 1ch;
}

.download-contract{
    color: #167AC0;
    font-weight: bolder;
    margin-bottom: 2ch;
    text-decoration: underline;
    cursor: pointer;
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .TuViaje-columns{
        flex-direction: column;
    }

    .TuViaje-columns > div{
        width: 100%;
        max-width: unset;
    }

    .TuViaje-columns > div > *{
        margin: 0;
    }

    .TuViaje-columns > div:nth-child(2) > p{
        margin-top: 2ch;
    }

}
</style>