<template>
    <div class="AgendarLlamada-MAIN">

        <h1 class="PopUp-title">Agendar Llamada</h1>
        <p>Deja tus datos y un asesor te contactará</p>
        
        <section>
            <p>Nombre</p>
            <div><img src="../../assets/images/usuario.svg"><input type="text" placeholder="Nombre Apellido" ref="nombre" required></div>
            <p>Telefono</p>
            <div><img src="../../assets/images/telefono.svg"><input type="tel" placeholder="55 5555 5555" ref="telefono" required></div>
            <p>Correo</p>
            <div><img src="../../assets/images/envelope.svg"><input type="email" placeholder="nombre@mail.com" ref="mail"></div>
            <p>Tipo de Depto.</p>
            <div><img src="../../assets/images/departamento.svg">
                <select ref="tipoDepto">
                    <option value="Departamento" selected>Departamento</option>
                </select>
            </div>
            <p>Equipamiento</p>
            <div><img src="../../assets/images/sofa.svg">
                <select ref="equipamiento">
                    <option value="Con Equipamiento" selected>Con Equipamiento</option>
                </select>
            </div>    
        </section>

        <button class="PopUp-btn" @click="agendar()">PAGAR</button>

    </div>
</template>

<script>
export default {
    name: 'AgendarLlamada',
    methods:{
        launchPopUp(mode_){
            this.$parent.launchPopUp(mode_);
        },

        agendar(){
            const data = {
                name: this.$refs.nombre.value,
                phone: this.$refs.telefono.value,
                mail: this.$refs.mail.value,
                type: this.$refs.tipoDepto.value,
                equipment: this.$refs.equipamiento.value
            };
            console.log(data);

            //fecth and
            //this.launchPopUp("RegistroExitoso");
            //this.launchPopUp("RegistroError");
        }
    }
}
</script>

<style scoped>
.AgendarLlamada-MAIN{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.AgendarLlamada-MAIN > h1{
    margin-bottom: 0;
}

.AgendarLlamada-MAIN > p{
    color: #152F62;
    font-size: 1.5ch;
    margin-top: 1ch;
    font-weight: 500;
}

section{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #152F62;
    width: 75%;
}

section p{
    font-weight: 600;
    margin-bottom: 0.5ch;
    width: 80%;
    position: relative;
}

section  div{
    display: flex;
    align-items: center;
    position: relative;
    width: 80%;
}

section div img{
    position: absolute;
    max-width: 2ch;
    left: -3ch;
}

section input, section select{
    padding: 0.8ch;
    border-radius: 5px;
    border: 1px solid gray;
    width: 100%;
}

section button{
    margin-top: 4ch;
}

button{
    margin-top: 4ch;
}
</style>