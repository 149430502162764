<template>
    <div class="SearchBarCel-MAIN" role="search">

        <select class="search-input" ref="input_rentTime" :value="searchData.rentTime" style="display: none !important;">
            <option value="Fin de semana">Fin de semana</option>
            <option value="Semana">Semana</option>
            <option value="Mes">Mes</option>
            <option value="Año">Año</option>
        </select>

        <div class="SearchBarCel-selectors" @click="triggerSelectors('open-cal')">
            <img src="../assets/images/calendario.svg">
            <div>
                <p>Fechas:</p>
                <span>{{`${arrivalSelected} - ${exitSelected}` }}</span>
            </div>
            <img src="../assets/images//arrow.svg">
        </div>

        <div class="SearchBarCel-selectors">
            <img src="../assets/images/usuarios-alt.svg">
            <div>
                <p style="white-space:nowrap; text-align:left;">Límite de huéspedes: 6 <!--<br><b>Máximo: 8 personas</b>--></p>
                <span>{{ selectedGuests }}</span>
            </div>
            <img src="../assets/images//arrow.svg">
            <select class="search-input" ref="input_guests" v-model="selectedGuests" @change="triggerSelectors('guests')">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
            </select>
        </div>

        <button class="btn" @click="search()">
            Buscar
        </button>

        <div class="incomplete-data-warning" v-if="incompleteDataWarning"><p>⚠️ La fecha de llegada y salida son obligatorias.</p></div>

        <section class="calendar-selectors" v-show="calendarSelectorsActive">
            <div class="search-cal-close-btn" @click="triggerSelectors('close-cal')">+</div>
            <div>
                <p><img src="../assets/images/calendario.svg">Fechas</p>
                <p>Llegada</p>
                <input type="date" onfocus="this.showPicker()" class="search-input" ref="input_arrival" :value="searchData.arrival">
                <p>Salida</p>
                <input type="date" onfocus="this.showPicker()" class="search-input" ref="input_exit" :value="searchData.exit">
                <button @click="triggerSelectors('accept-cal')">Aceptar</button>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: 'SearchBarCel',
    props:{
        searchData: Object
    },
    data(){
        return{
            selectedGuests: 0,
            calendarSelectorsActive: false,
            arrivalSelected: 'Agregar',
            exitSelected: 'Fechas',
            incompleteDataWarning: false,
        }
    },
    methods:{
        search(){
            if(this.arrivalSelected == "" || this.arrivalSelected == "Agregar" || this.exitSelected == "" || this.exitSelected == "Fechas"){
                this.incompleteDataWarning = true;
                return;
            }

            this.incompleteDataWarning = false;
            const searchData = {
                rentTime: this.$refs.input_rentTime.value,
                arrival: this.arrivalSelected,
                exit: this.exitSelected,
                guests: this.$refs.input_guests.value
            };
            console.log("test", searchData);
            this.$parent.setSearchData(searchData);
        },

        triggerSelectors(selector_){
            if(selector_ == "guests"){
                this.selectedGuests = this.$refs.input_guests.value;
            }

            if(selector_ == "close-cal"){
                this.calendarSelectorsActive = false;
            }

            if(selector_ == "open-cal"){
                this.calendarSelectorsActive = true;
            }

            if(selector_ == "accept-cal"){
                if(this.$refs.input_arrival.value == "" || this.$refs.input_exit.value == ""){
                    return;
                }else{
                    this.arrivalSelected = this.$refs.input_arrival.value.replaceAll("-", "/");
                    this.exitSelected = this.$refs.input_exit.value.replaceAll("-", "/");
                    this.calendarSelectorsActive = false;
                }
            }
        }
    },
    mounted(){
        if(this.searchData.arrival != '' && this.searchData.exit != ''){
            this.arrivalSelected = this.searchData.arrival;
            this.exitSelected = this.searchData.exit;
        }
        this.selectedGuests = this.searchData.guests;
    }
}
</script>

<style scoped>
.SearchBarCel-MAIN{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SearchBarCel-selectors{
    padding: 1ch 2ch;
    background-color: white;
    border-radius: 100vw;
    margin-bottom: 8vw;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.SearchBarCel-selectors > div{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 60%;
}

.SearchBarCel-selectors > div p{
    margin: 0;
    color: #167AC0;
}

.SearchBarCel-selectors > div span{
    color: black;
    font-size: 1.3ch;
    white-space: nowrap;
}

.SearchBarCel-selectors select{
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.01;
}

button{
    height: 6ch;
    border: none;
    padding: 0 1ch;
    background-color: #BEAF94;
    font-weight: 600;
    color: white;
    width: 15ch;
    min-width: 40%;
    text-transform: uppercase;
}

/* calendars */
.calendar-selectors{
    position: fixed;
    background-color: #F4F4F4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.calendar-selectors > div {
    background-color: white;
    border-radius: 1ch;
    margin: 6vw;
    padding: 5vw;
    padding-bottom: 10vw;
    box-shadow: 0vw 0.5vw 1vw rgba(100, 100, 100, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.calendar-selectors > div > p{
    color: #167AC0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5vw;
    margin-top: 10vw;
}

.calendar-selectors > div > p img{
    margin-right: 1ch;
}

.calendar-selectors > div > p:nth-child(1){
    font-size: 6vw;
    margin-top: 5vw;
}

.calendar-selectors > div button{
    margin-top: 15vw;
    background-color: #167AC0;
    text-transform: uppercase;
    font-size: 3.5vw;
}

.calendar-selectors input{
    padding: 1ch;
}

.calendar-selectors > div:not(.search-cal-close-btn){
    margin-top: 22vw;
}

.search-cal-close-btn{
    color: gray;
    width: 1ch;
    height: 1ch;
    font-size: 15vw;
    margin: 0;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    rotate: 45deg;
    right: 0ch;
    background-color: transparent !important;
    box-shadow: unset !important;
}

.incomplete-data-warning{
    margin-bottom: 0;
    background-color: white;
    color: red;
    padding: 0.5ch;
    margin-top: 1ch;
    border-radius: 100vw;
}

.incomplete-data-warning p{
    margin: 0;
}
</style>