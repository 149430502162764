<template>
  <TheOneLife/>
</template>

<script>
import TheOneLife from './components/TheOneLife.vue';

export default {
  name: 'App',
  components: {
    TheOneLife
  }
}
</script>

<style>
</style>
