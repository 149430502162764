<template>
    <div class="EndPage-MAIN">

        <div class="airbnb-section" data-aos="fade-right">
            <p>¿Aún no te sientes seguro?</p>
            <p>¡Tambien puedes reservar a través de Airbnb!</p>
            <button>
                RESERVAR EN
                <img src="../assets/images/airbnb.svg"/>
            </button>
        </div>

        <div class="map-section" data-aos="fade-left">
            <div class="INNER-MARGIN">
                <div>
                    <p class="footer-title">SAND DIAMANTE</p>
                    <p>
                        Boulevard de las Naciones km 15.05<br>
                        Col. Plan de los Amates<br>
                        C.P. 39897, Acapulco de Juárez, Gro.
                    </p>
                </div>
            </div>

            <div class="map-wrap">
                <img src="../assets/images/map.png">
            </div>
        </div>

        <footer data-aos="fade-up">
            <div class="INNER-MARGIN">
                <div>
                    <img src="../assets/images/sand-logo.svg" class="footer-icon">
                </div>
                <div class="footer-info-center">
                    <p class="footer-title">SAND DIAMANTE</p>
                    <p>
                        Boulevard de las Naciones km 15.05<br>
                        Col. Plan de los Amates<br>
                        C.P. 39897, Acapulco de Juárez, Gro.
                    </p>
                </div>
                <div class="footer-info-net">
                    <a href="https://api.whatsapp.com/send?phone=5632432372" target="_blank"><div class="footer-text"><div><img src="../assets/images/whatsapp.svg"></div>56-3243-2372</div></a>
                    <a href="https://www.facebook.com/SandDiamante.Acapulco" target="_blank"><div class="footer-text"><div><img src="../assets/images/facebook.svg"></div>Sand Diamante</div></a>
                    <a href="https://www.instagram.com/sand.diamante/" target="_blank"><div class="footer-text"><div><img src="../assets/images/instagram.svg"></div>sand.diamante</div></a>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
export default {
    name: 'EndPage'
}
</script>

<style scoped>
.EndPage-MAIN{
    font-family: 'Montserrat', sans-serif;
}

/* airbnb-section */
.airbnb-section{
    background-color: #BEAF94;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 260px;
}

.airbnb-section p{
    color: white;
    font-weight: 500;
    font-size: 1.8ch;
}

.airbnb-section p:nth-child(1){
    margin: 0;
    font-size: 2.4ch;
    font-weight: bold;
}

.airbnb-section button{
    background-color: white;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2ch;
    height: 6ch;
    color: #003567;
    margin-top: 1ch;
}

.airbnb-section button img{
    margin-left: 1ch;
    width: 14ch;
}

/* map section */
.map-section{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 490px;
    color: #003567;
    font-weight: 500;
    overflow: hidden;
    position: relative;
}

.map-wrap{
    height: 500px;
    display: flex;
    position: absolute;
    left: 42vw;
}

.map-wrap img{
    height: 100%;
}

.map-section .footer-title{
    color: #BEAF94;
    margin-bottom: 0;
}

/* footer */
footer{
    color: white;
    background-color: #167AC0;
    background-color: #F3F3F4;
    color: #003567;
    height: 20ch;
    border-bottom: 3.5ch solid #003567;
    display: flex;
    align-items: center;
    justify-content: center;
}

footer > .INNER-MARGIN{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-icon{
    width: 130%;
}

.footer-title{
    font-weight: bold;
    font-size: 2ch;
    margin-bottom: default;
}

.footer-text{
    display: flex;
    align-items: center;
    margin: 1.5ch 0;
}

.footer-text > div{
    width: 2.5ch;
    margin-right: 1ch;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-text img{
    max-width: 2.2ch;
    max-height: 2.2ch;
}

.footer-info-center{
    display: none;
}

.footer-info-net{
    font-weight: 600;
}

a{
    text-decoration: none;
    color: #003567;
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .airbnb-section > *{
        text-align: center;
        max-width: 95%;
    }

    .airbnb-section p:nth-child(1){
        font-size: 7vw;
    }

    .airbnb-section p:nth-child(2){
        font-size: 5vw;
    }
    
    .airbnb-section button{
        scale: 0.9;
    }

    /* map section */
    .map-section{
        flex-direction: column;
        padding-top: 0;
        height: fit-content;
    }

    .map-section *{
        position: relative !important;
    }

    .map-section .INNER-MARGIN p{
        font-size: 5vw;
    }

    .footer-title{
        font-size: 8vw !important;
    }

    .map-wrap{
        position: relative;
        height: fit-content;
        width: 100%;
        left: unset;
    }

    .map-wrap img{
        height: unset;
        width: 100%;
        object-fit: cover;
        object-position: center;
        aspect-ratio: 1.5/1;
    }

    /* footer */
    footer{
        height: 28ch;
    }

    footer > .INNER-MARGIN{
        flex-direction: column;
        align-items: center !important;
        justify-content: unset;
        margin: 0;
        max-height: unset;
    }

    footer *:not(.footer-text){
        margin: 0 !important;
    }

    footer > .INNER-MARGIN > div{
        margin: 0;
        width: 100%;
    }

    .footer-icon{
        width: 100%;
        scale: 0.85;
        padding-bottom: 1ch !important;
    }

    footer > .INNER-MARGIN > div:nth-child(3){
        width: fit-content;
        margin-bottom: 4ch;
    }

    .footer-text{
        width: fit-content;
    }
}
</style>