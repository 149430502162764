<template>
    <div class="ProcesoError-MAIN">

        <img :src="type == 'RegistroError' ? require('../../assets/images/registro-exitoso.png') : require('../../assets/images/pago-exitoso.png')">

        <div>
            <img :src="type == 'RegistroError' ? require('../../assets/images/error-yellow.svg') : require('../../assets/images/error-blue.svg')">
            <h1 class="PopUp-title">{{ title }}</h1>
            <p>{{ text }}</p>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ProcesoError',
    props:{
        type: String,
        title: String,
        text: String
    }
}
</script>

<style scoped>
.ProcesoError-MAIN{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2ch;
}

.ProcesoError-MAIN h1{
    margin-top: 0.5ch;
    margin-bottom: 0.5ch;
    text-align: center;
}

.ProcesoError-MAIN > img{
    width: 30ch;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.ProcesoError-MAIN > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25ch;
    margin-left: 4ch;
}

.ProcesoError-MAIN > div img{
    max-width: 8ch;
    max-height: 8ch;
}

.ProcesoError-MAIN > div > div{
    display: flex;
    align-items: center;
    margin-top: 1ch;
}

.ProcesoError-MAIN > div > div img{
    max-width: 3ch;
    max-height: 3ch;
    margin-right: 1ch;
}

/* RESPONSIVE */
@media all and (max-width: 600px) {

    .ProcesoError-MAIN{
        flex-direction: column;
        align-items: center;
    }

    .ProcesoError-MAIN > img{
        aspect-ratio: 1/1 !important;
        width: 80% !important;
    }

    .ProcesoError-MAIN > div > img{
        margin-top: 2ch;
    }

}
</style>