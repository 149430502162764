<template>
    <div class="ImgSider-MAIN" ref="ImgSliderMAIN">
  
        <div v-for="(imgSrc, index) in finalImgList" :key="index"
            class="ImgSlider-div"
            :style="`background-image: url('${imgSrc}')`"> 
        </div>
  
    </div>
</template>

<script>
export default {
    name: 'ImgSlider',
    props:{
        imgList: Array
    },
    data(){
        return{
            finalImgList: [...this.imgList, this.imgList[0]],
            counter: 0
        }
    },
    methods:{

    },

    mounted(){
        const imgLen = this.imgList.length;
        const slider = this.$refs.ImgSliderMAIN;
        const sliderWidth = slider.offsetWidth;

        setInterval(() => {
            this.counter += 1;
            slider.style.scrollBehavior = "smooth";
            slider.scrollLeft = sliderWidth * this.counter;
            if(this.counter == imgLen){
                setTimeout(() => {
                    this.counter = 0;
                    slider.style.scrollBehavior = "auto";
                    slider.scrollLeft = 0;
                }, 500);
            }
        }, 3000);
    }
}
</script>

<style scoped>
.ImgSider-MAIN{
    display: flex;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow: hidden;
}

.ImgSlider-div{
    height: 100%;
    width: 100%;
    flex: 0 0 auto; /* Establecemos el ancho completo para cada div */
    background-size: cover;
    background-position: center;
}
</style>