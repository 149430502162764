<template>
    <div class="searchbar" role="search">

        <div style="display:none;">
            <p><img src="../assets/images/home.svg">Tiempo de renta:</p>
            <select class="search-input" ref="input_rentTime" :value="searchData.rentTime">
                <option value="Fin de semana">Fin de semana</option>
                <option value="Semana">Semana</option>
                <option value="Mes">Mes</option>
                <option value="Año">Año</option>
            </select>
            <img src="../assets/images//arrow.svg" class="search-arrow"/>
        </div>
        <div style="margin-left: 10%;">
            <p><img src="../assets/images/calendario.svg">Llegada:</p>
            <input type="date" onfocus="this.showPicker()" class="search-input" ref="input_arrival" :value="searchData.arrival">
            <img src="../assets/images//arrow.svg" class="search-arrow"/>
        </div>
        <div>
            <p><img src="../assets/images/calendario.svg">Salida:</p>
            <input type="date" onfocus="this.showPicker()" class="search-input" ref="input_exit" :value="searchData.exit">
            <img src="../assets/images//arrow.svg" class="search-arrow"/>
        </div>
        <div>
            <p style="margin-bottom: 0;"><img src="../assets/images/usuarios-alt.svg">Límite de huéspedes: 6</p>
            <!--<p style="margin:0; margin-left:2.8ch; margin-top:-1ch; margin-bottom:-1ch; z-index:2;"><br><b>Máximo: 8 personas</b></p>-->
            <select class="search-input" ref="input_guests" :value="searchData.guests">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
            </select>
            <img src="../assets/images//arrow.svg" class="search-arrow"/>
        </div>
        <div @click="search()" class="btn-search">
            <img src="../assets/images/search.svg">
            <span>BUSCAR</span>
        </div>
        

        <section class="incomplete-data-warning" v-if="incompleteDataWarning"><p>⚠️ La fecha de llegada y salida son obligatorias.</p></section>

    </div>
</template>

<script>
export default {
    name: 'SearchBar',
    props:{
        searchData: Object,
    },
    data(){
        return{
            incompleteDataWarning: false,
        }
    },
    methods:{
        search(){
            if(this.$refs.input_arrival.value == "" || this.$refs.input_exit.value == ""){
                this.incompleteDataWarning = true;
                return;
            }

            this.incompleteDataWarning = false;
            const searchData = {
                rentTime: this.$refs.input_rentTime.value,
                arrival: this.$refs.input_arrival.value,
                exit: this.$refs.input_exit.value,
                guests: this.$refs.input_guests.value
            };
            this.$parent.setSearchData(searchData);
        }
    }
}
</script>

<style scoped>
.searchbar{
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: 100vw;
    margin-top: 3ch;
    box-shadow: 0ch 0ch 1ch rgba(0, 0, 0, 0.25);
}

.searchbar > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #167AC0;
    position: relative;
    height: 6.5ch;
}

.search-arrow{
    position: absolute;
    right: 0.5ch;
    bottom: 0.5ch;
    background-color: white;
    padding: 0.5ch 0;
    pointer-events: none;
    max-width: 2.2ch;
    max-height: 2ch;
}

.searchbar > div:not(:nth-child(5)) p img{
    margin-right: 1ch;
    max-width: 1.8ch;
    max-height: 1.8ch;
}

.searchbar > div:nth-child(1){
    margin-left: 4ch;
}

.searchbar > div:nth-child(5){
    background-color: #BEAF94;
    color: white;
    flex-direction: row;
    align-items: center;
    padding-left: 2ch;
    padding-right: 3ch;
    border-radius: 0 100vw 100vw 0;
}

.searchbar > div:nth-child(5) span{
    font-weight: bold;
}

.searchbar > div:nth-child(5) img{
    margin-right: 2ch;
}

.searchbar > div p{
    display: flex;
    align-items: center;
    margin-top: 1ch;
    margin-bottom: 0.5ch;
    font-weight: 500;
}

.search-input{
    border: none;
    padding: 0.5ch 1ch;
    border-bottom: 3px solid #167AC0;
}

.btn-search{
    margin-right: -1px;
}

.btn-search:hover{
    cursor: pointer;
    filter: brightness(1.1);
    transition: all 0.2s;
}

.btn-search:active{
    filter:brightness(0.9);
    transition: all 0.1s;
}

.incomplete-data-warning{
    margin-bottom: 0;
    background-color: white;
    color: red;
    padding: 0.5ch 1.5ch;
    margin-top: 8ch;
    border-radius: 100vw;
    position: absolute;
    width: fit-content;
    margin-left: 25vw;
}

.incomplete-data-warning p{
    margin: 0 !important;
    padding: 0 !important;
}
</style>